<template>
	<div id="page-content">
		<!-- Sidenav -->
		<TheNavSide :items="navItems">
			<template v-slot:top>
				<h1>Admin</h1>
			</template>
		</TheNavSide>

		<!-- Body -->
		<div id="body">
			<router-view />
		</div>
	</div>
</template>

<script>
import TheNavSide from '@/components/TheNavSide'

export default {
	name: 'AdminMain',
	components: { TheNavSide },
	data() {
		return {
			sel: 'Admin',
			// Name is for router
			navItems: [
				{
					display: 'Monitor',
					children: [
						{
							display: 'Wait List',
							to: {
								name: 'AdminMonitorNewPeople',
								params: { entityType: 'wait-list' },
							},
						},
						{
							display: 'New Users',
							to: {
								name: 'AdminMonitorNewPeople',
								params: { entityType: 'new-users' },
							},
						},
					],
				},
				{
					display: 'Index',
					children: [
						{
							display: 'Users',
							to: {
								name: 'AdminIndexEntity',
								params: { entityType: 'users' },
							},
						},
						{
							display: 'Artists',
							to: {
								name: 'AdminIndexEntity',
								params: { entityType: 'artists' },
							},
						},
						{
							display: 'Artworks',
							to: {
								name: 'AdminIndexEntity',
								params: { entityType: 'artworks' },
							},
						},
						{
							display: 'Lists',
							to: {
								name: 'AdminIndexEntity',
								params: { entityType: 'lists' },
							},
						},
						{
							display: 'Rooms',
							to: {
								name: 'AdminIndexEntity',
								params: { entityType: 'rooms' },
							},
						},
					],
				},
				{
					display: 'Maintain',
					to: 'AdminMaintain',
				},
				{
					display: 'Inspect',
					to: 'AdminInspect',
				},
				{
					display: 'Sitemaps',
					to: 'AdminSitemaps',
				},
				{
					sep: true,
				},
				{
					display: 'Docs',
					to: 'Doc',
				},
				{
					display: 'Home',
					to: 'Home',
				},
				// {
				// 	to: 'Manage',
				// },
				// {
				// 	to: 'Quality',
				// },
				// {
				// 	to: 'Moderation',
				// },
				// {
				// 	to: 'Email',
				// },
			],
		}
	},
}
</script>

<style lang="scss" scoped src="../../assets/css/side-nav-structure.scss"></style>
